import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const AgriBusiness = () => {
  const title = "Agribusiness - Loans & Financing";
  const description =
    "Committed to supporting our nation's agriculture industry, WaFd Bank has a dedicated team to help grow your agribusiness. Learn more here.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/agribusiness-banking/hero-agriculture-012623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/specialized-industries/agribusiness-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-agriculture-012623.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "agriBusiness-hero",
    ...getHeroImgVariables(imgData),
    altText: "Farmer standing in front of a tractor.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Open Your Cashflow With CollectEarly<sup>&reg;</sup>",
            class: "text-white"
          }
        },
        {
          id: 3,
          button: {
            type: "button",
            id: "agribusiness-banking-hero-cta",
            class: "btn-white",
            text: "Contact Us",
            onClick: () => showMktoForm(1067)
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/specialized-industries",
      title: "Specialized Industries"
    },
    {
      id: 3,
      active: true,
      title: "Agribusiness"
    }
  ];

  const equipmentLoansListData = {
    greenCircleCheckmark: true,
    accountName: "equipment-loans",
    items: [
      {
        id: 1,
        text: "Variable or fixed interest rates"
      },
      {
        id: 2,
        text: "Fair market value lease payment options"
      },
      {
        id: 3,
        text: "Flexible amortization schedules"
      }
    ]
  };

  const realEstateLoansListData = {
    greenCircleCheckmark: true,
    accountName: "real-estate-loans",
    items: [
      {
        id: 1,
        text: "Competitive interest rates"
      },
      {
        id: 2,
        text: "Flexible maturity dates"
      }
    ]
  };

  const operatingLoansListData = {
    greenCircleCheckmark: true,
    accountName: "operating-loans",
    items: [
      {
        id: 1,
        text: "Competitive interest rates"
      },
      {
        id: 2,
        text: "Flexible maturity dates"
      },
      {
        id: 3,
        text: "Revolving lines available"
      },
      {
        id: 4,
        text: "Production lines of credit tailored to your commodity"
      }
    ]
  };

  const linesOfCreditListData = {
    greenCircleCheckmark: true,
    accountName: "revolving-lines-of-credit",
    items: [
      {
        id: 1,
        text: "Ready when you need it"
      },
      {
        id: 2,
        text: "Secured by crops, livestock, equipment, chattels or real estate"
      },
      {
        id: 3,
        text: "Maximum flexibility to use and pay down when it suits you"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Agribusiness Loans &amp; Financing</h1>
        <h3>
          At WaFd Bank, our Agribusiness Team has practical knowledge and common sense to assist you with your
          agricultural financing needs. We know that agriculture is vital to the communities we operate in, large or
          small. We're here to help you meet your goals, whether that's through renewable energy opportunities that can
          help reduce dependency on foreign oil or growing your farm to produce more fresh, local food products to our
          communities.
        </h3>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <StaticImage
                src="../../../images/thumbnail-agribusiness-030724.jpg"
                alt="Women working picking vegetables in a large greenhouse."
                placeholder="blurred"
                className="border-radius-12"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2>The goal of WaFd Bank's Agribusiness Division is to provide:</h2>
              <h5 className="mb-0 text-success">A Dedicated Relationship Manager</h5>
              <p>Not just for a transaction, but for the long-term. Someone who knows and cares about the operation.</p>
              <h5 className="mb-0 text-success">Flexibility</h5>
              <p>
                Products designed to help clients meet their goals, tailored to the seasonality that may come with
                irregular cash flows for repayments.
              </p>
              <h5 className="mb-0 text-success">Competitive Products</h5>
              <p>Solutions, knowledge and insight to keep clients ahead of the curve.</p>
              <h5 className="mb-0 text-success">Commitment to Community</h5>
              <p>A financial provider that invests its money and people where its business is.</p>
              <a
                id="contact-commercial-banker-form-link"
                className="btn btn-primary w-100 w-sm-auto no-min-width"
                href="/#"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  showMktoForm(1067);
                }}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <h2 className="text-success">Ag Lending Products</h2>
        <div className="row">
          <div className="col-md-6">
            <h4 className="font-weight-bold">Equipment Loans</h4>
            <h4>Tractors, tillage or planning equipment</h4>
            <List {...equipmentLoansListData} />
          </div>
          <div className="col-md-6">
            <h4 className="font-weight-bold">Real Estate Loans</h4>
            <h4>Purchase property, refinance debt, make capital improvements</h4>
            <List {...realEstateLoansListData} />
          </div>
          <div className="col-md-6">
            <h4 className="font-weight-bold">Operating Loans</h4>
            <h4>Capitalize on opportunities, ride out challenges</h4>
            <List {...operatingLoansListData} />
          </div>
          <div className="col-md-6">
            <h4 className="font-weight-bold">Revolving Lines of Credit</h4>
            <h4>Use it any way you like&mdash;investing in the business, cash flow, or debt reduction</h4>
            <List {...linesOfCreditListData} />
            <p className="mb-0">
              <strong>Livestock Financing</strong> is also available from specialists that can tailor financial
              solutions to meet specific operating needs.
            </p>
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default AgriBusiness;
